(function ($) {
  // Check if jQuery is loaded
  if (typeof $ === "undefined") {
    console.error("jQuery is not loaded!");
    return;
  }

  // Use this variable to set up the common and page-specific functions.
  var Connectere = {
    // All pages
    common: {
      init: function () {
        const currentUrl = window.location.href;
        // JavaScript to be fired on all pages
        // Mobile navigation
        $(".js-menu-mobile").click(function (e) {
          e.preventDefault();
          $(this).toggleClass("active");
          $(".navigation").toggleClass("navigation--active");
        });

        $(".navigation-menu li a").each(function () {
          const linkUrl = $(this).attr("href");

          const currentPath = currentUrl.split("//").pop();

          const linkPath = linkUrl.split("//").pop();

          if (currentPath.startsWith(linkPath)) {
            $(this).addClass("active");
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page-specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page-specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Connectere;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      /* jshint ignore:start */ // Ignorado por erro com trailing comma
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        },
      );
      /* jshint ignore:end */

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(function () {
    // Log a message when the document is ready

    // Load events
    UTIL.loadEvents();
  });
})(jQuery); // Fully reference jQuery after this point.
